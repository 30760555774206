import { imageURL2 } from "../images";

export const engagementGridHomeProduct = (imageURL2) => [
  {
    id: 1,
    name: "SOLITAIRE",
    // image: imageURL2?.image_23,
    image: imageURL2.image_26,
    link: "/solitaire-engagement-rings",
  },
  {
    id: 2,
    name: "SIDE STONE",
    image: imageURL2.image_27,
    link: "/side-stone-engagement-rings",
  },
  {
    id: 3,
    name: "HALO",
    image: imageURL2.image_28,
    link: "/halo-engagement-rings",
  },
  {
    id: 4,
    name: "HIDDEN HALO",
    image: imageURL2.image_29,
    link: "/hidden-halo-engagement-rings",
  },
  {
    id: 5,
    name: "THREE STONE",
    image: imageURL2.image_30,
    link: "/three-stone-engagement-rings",
  },
  {
    id: 6,
    name: "BRIDAL SETS",
    image: imageURL2.image_31,
    link: "/fashion-jewelry-rings",
  },
];

export const engagementReviewProduct = (imageURL2) => [
  {
    id: 1,
    first_name: "Elina",
    last_name: "R",
    image: imageURL2.image_151,
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review: "Super happy with this ring. Very sparkly and secure.",
    status: 1,
    created_at: "2024-05-06T17:21:57.000000Z",
    updated_at: "2024-05-11T14:50:09.000000Z",
  },
  {
    id: 2,
    first_name: "Jenna",
    last_name: "L",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "This ring is absolutely gorgeous. Sparkles like crazy in the light and my best friend loves it so much she’s getting one too. The diamonds are bright and beautiful!",
    status: 1,
    image: imageURL2.image_152,
    created_at: "2024-05-06T17:23:02.000000Z",
    updated_at: "2024-05-11T14:49:38.000000Z",
  },
  {
    id: 3,
    first_name: "Jassica",
    last_name: "S",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    image: imageURL2.image_153,
    review:
      "I loved this ring from the time I first saw it and I'm telling you, it is just as beautiful",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
  {
    id: 4,
    first_name: "Madison",
    last_name: "B",
    image: imageURL2.image_154,
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "Upgraded as a push present and I love it! It’s so sparkly and just perfect. Will be sending my husband hints for more items when we hit our next milestones.",
    status: 1,
    created_at: "2024-05-06T17:21:57.000000Z",
    updated_at: "2024-05-11T14:50:09.000000Z",
  },
  {
    id: 5,
    first_name: "Renee",
    last_name: "G",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "I love it! I received the ring and it was beautiful and fit perfectly. Package arrived on time and was beautifully packed. Great product at a phenomenal price compared to mined diamonds. many compliments.",
    status: 1,
    image: imageURL2.image_155,
    created_at: "2024-05-06T17:23:02.000000Z",
    updated_at: "2024-05-11T14:49:38.000000Z",
  },
  {
    id: 6,
    first_name: "Phillip",
    last_name: "B",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    image: imageURL2.image_156,
    review:
      "My wife was enchanted with the ring. I was late to get her an engagement ring and after seeing it, she said it was worth the wait! Thank you!",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
  {
    id: 7,
    first_name: "James",
    last_name: "H",
    image: imageURL2.image_157,
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "Just fine! She loved it and I am thrilled with your workmanship. Everything was perfect from ordering to delivery..",
    status: 1,
    created_at: "2024-05-06T17:21:57.000000Z",
    updated_at: "2024-05-11T14:50:09.000000Z",
  },
  {
    id: 8,
    first_name: "Jose",
    last_name: "O",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    review:
      "Ring itself my finance loves it! The I was a little apprehensive but the process was easy. AL kept me updated on my order. The packaging was really nice. The ring is amazing. The stone wasn't lose. Also, this was the best deal I could find for this style and diamond size. I was glad I didn't have to sort through hundreds of choices and pick one",
    status: 1,
    image: imageURL2.image_158,
    created_at: "2024-05-06T17:23:02.000000Z",
    updated_at: "2024-05-11T14:49:38.000000Z",
  },
  {
    id: 9,
    first_name: "Taylor",
    last_name: "M",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    image: imageURL2.image_159,
    review:
      "We had a great experience with Andor Luxury from start to finish. The selection, website, quality and the process were all top notch. I rarely give reviews, but felt compelled to with them.",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
  {
    id: 10,
    first_name: "Charles",
    last_name: "L",
    sku: "AL3-JMR.PR-W4",
    star: 5,
    image: imageURL2.image_160,
    review:
      "What an amazing experience shopping for an engagement ring. Th folks at AL made it feel like selecting a ring from the family heirloom. I did not feel like a purchase, it felt more like a gift. Than you do much for making us feel like family.",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
];
export const weddingReviewProduct = (imageURL2) => [
  {
    id: 1,
    first_name: "Sarah",
    last_name: "Mitchell",
    // image: imageURL2.image_151,
    image:
      "https://andorluxury.blr1.cdn.digitaloceanspaces.com/7504/KLR-4007D_WG0.jpg",
    sku: "AL4-WEB.RD-4W4",
    star: 5,
    review:
      "Now I see why everyone talks about Andor Luxury! My experience with their eternity band has been amazing from start to finish. The quality of the band is exceptional, and the packaging is just as impressive. The service? WOW! I'm beyond thrilled with my purchase.",
    status: 1,
    created_at: "2024-05-06T17:21:57.000000Z",
    updated_at: "2024-05-11T14:50:09.000000Z",
  },
  {
    id: 2,
    first_name: "Olivia",
    last_name: "Parker",
    sku: "AL3-WEB.EM-W4",
    image: "https://andorluxury.blr1.cdn.digitaloceanspaces.com/1642/09.jpg",
    star: 5,
    review:
      "I'm beyond happy with this wedding band! I bought it for my fiancée, and it's even more beautiful in person. The customer service team was incredibly helpful, patiently answering all my anxious questions about engraving and delivery. I'm very pleased with this purchase of a lifetime.",
    status: 1,
    // image: imageURL2.image_152,
    created_at: "2024-05-06T17:23:02.000000Z",
    updated_at: "2024-05-11T14:49:38.000000Z",
  },
  {
    id: 3,
    first_name: "Jessica",
    last_name: "Miller",
    sku: "AL3-WEB.RD-2W4",
    star: 5,
    // image: imageURL2.image_153,
    image:
      "https://andorluxury.blr1.cdn.digitaloceanspaces.com/5627/media-libraryYxph0W.jpg",

    review:
      "I absolutely love this ring! It's such a great deal compared to other jewelers. The setting really lets the diamonds shine, and it's so comfortable to wear. The quality is outstanding. I'm officially a fan of Andor Luxury :)",
    status: 1,
    created_at: "2024-05-11T14:46:00.000000Z",
    updated_at: "2024-05-11T14:49:08.000000Z",
  },
];

export const engagementRingEleganceProduct = (imageURL2) => [
  {
    id: 1,
    name: "ROUND",
    image: imageURL2.image_32,
    link: "/round-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 2,
    name: "OVAL",
    image: imageURL2.image_33,
    link: "/oval-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 3,
    name: "CUSHION",
    image: imageURL2.image_34,
    link: "/cushion-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 4,
    name: "EMERALD",
    image: imageURL2.image_35,
    link: "/emerald-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 5,
    name: "PEAR",
    image: imageURL2.image_36,
    link: "/pear-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 6,
    name: "HEART",
    image: imageURL2.image_37,
    link: "/heart-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 7,
    name: "RADIANT",
    image: imageURL2.image_143,
    link: "/radiant-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 8,
    name: "PRINCESS",
    image: imageURL2.image_144,
    link: "/princess-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 9,
    name: "MARQUISE",
    image: imageURL2.image_145,
    link: "/marquise-engagement-rings",
    // description: text?.text_196,
  },
  {
    id: 10,
    name: "ASSCHER",
    image: imageURL2.image_146,
    link: "/asscher-engagement-rings",
    // description: text?.text_196,
  },
];

export const engagementGridHomeRongProduct = (imageURL2) => [
  {
    id: 1,
    name: "WHITE GOLD",
    image: imageURL2.image_25,
    link: "/white-gold-engagement-rings",
  },
  {
    id: 2,
    name: "YELLOW GOLD",
    image: imageURL2.image_24,
    link: "/yellow-gold-engagement-rings",
  },
  {
    id: 3,
    name: "ROSE GOLD",
    image: imageURL2.image_23,
    link: "/rose-gold-engagement-rings",
  },
];

export const engagementFAQ = () => [
  {
    question: "How much should I spend on an engagement ring?",
    answer:
      "No matter what, diamonds are a huge investment. However, there is no 'one size fits all' answer. Andor Luxury has a wide range of engagement rings to suit all budgets.",
  },
  {
    question: "What size diamond is best for an engagement ring?",
    answer:
      "The most popular diamond size chosen for engagement rings is a 1.0 carat center stone. However, couples can opt for both smaller and larger diamonds. Another great and more affordable option at Andor Luxury is lab grown diamonds. Lab grown diamonds are the next generation of diamonds that give you the benefit of purchasing a larger size diamond at a more affordable price.",
  },
  {
    question: "What are the different diamond shapes for engagement rings?",
    answer:
      "There is a selection of diamond shapes to choose from. These include round, princess, cushion, emerald, oval, radiant, Asscher, marquise, pear and heart shaped diamonds.",
  },
  {
    question: "How to choose diamond engagement rings?",
    answer:
      "Buying an engagement ring comes with many questions. At Andor Luxury, we're here to offer you 100% free advice and will assist you in understanding the best diamond quality for your budget and unique preferences. Our non-commissioned diamond and jewelry experts are available 24/7. Contact us here.",
  },
  {
    question: "What are the 4Cs?",
    answer:
      "The 4Cs include carat (size), color, clarity and cut, and are the globally accepted standards by the Gemological Institute of America (GIA) used to assess the quality of the diamond.",
  },
  {
    question: "How to measure ring size?",
    answer:
      "There are several methods available to determine your ring size (or the ring size of a loved one). Please click here for a full list of the options available to you.",
  },
  {
    question: "What hand do engagement rings go on?",
    answer:
      "Traditionally, an engagement ring is worn on the left hand. However, in many countries and cultures around the world this may differ.",
  },
];

export const contactUsFAQ = () => [
  {
    question: "How can I contact customer support?",
    answer: (
      <p>
        You can reach our customer support team by emailing{" "}
        <a href="mailto:info@andorluxury.com">info@andorluxury.com</a> , calling
        +91 7600510079, or filling out the contact form on this page.
      </p>
    ),
  },
  {
    question: "What’s the best way to get the status of my order?",
    answer: (
      <p>
        As all our jewelry is made to order, the best way to get all the details
        on your order is emailing us at{" "}
        <a href="mailto:info@andorluxury.com">info@andorluxury.com</a>. We can
        also assist you via live chat or phone to get you information right away
        about your ring or jewelry order.
      </p>
    ),
  },
  {
    question: "How do I order custom jewelry or a custom ring?",
    answer:
      "We specialize in custom jewelry and rings. Simply email us or submit a inquiry with the specifications of what you have in mind. We will get back to you within a day with a pricing quote and a way to make your custom jewelry vision come to life. ",
  },
  {
    question: "Where can I learn more about Andor Luxury policies?",
    answer:
      "For all general questions, you can see our policies about warranties, insurance, returns and more. For anything specific, please feel free to email, live chat or call us at +91 7600510079 and we can provide you with quick answers to your questions.",
  },
  {
    question: "Why should I choose Andor Luxury?",
    answer:
      "For a luxurious jewelry experience, choose Andor Luxury. We curate a stunning collection of ethically sourced pieces, ensuring both quality and responsible practices. Discover competitive pricing, a wide range of styles, and exceptional customer service, all through a secure online platform. Find your perfect treasure – explore Andor Luxury today!",
  },
];

export const requestFormFields = {
  "general-help": [
    {
      type: "email",
      name: "email",
      label: "Your email address",
      placeholder: "Enter your email address",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "phone",
      label: "Phone Number",
      placeholder: "Enter your phone number",
      size: 12,
      required: false,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Enter your first name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter your last name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "subject",
      label: "Subject",
      placeholder: "Enter your subject",
      size: 12,
      required: true,
      extraText: "Tell us a few words about your question",
    },

    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "Enter your description",
      size: 12,
      required: false,
      extraText:
        "Please enter any additional details regarding your request. If no additional details please enter 'none'",
    },

    {
      type: "file",
      name: "file",
      label: "File Upload",
      size: 12,
      required: true,
    },
  ],
  "retrun-my-item": [
    {
      type: "email",
      name: "email",
      label: "Your email address",
      placeholder: "Enter your email address",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "phone",
      label: "Phone Number",
      placeholder: "Enter your phone number",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Enter your first name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter your last name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "orderNumber",
      label: "Provide your order number",
      placeholder: "Enter your order number",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "itemNumber",
      label: "Please provide your item number(s) that you would like to return",
      size: 12,
      placeholder: "Enter your item number",
      required: true,
      extraText:
        "For multiple items, provide each item number separated by a comma.",
    },
    {
      type: "text",
      name: "return-reason",
      label: "Reason for return(optional)",
      size: 12,
      required: false,
    },
    {
      type: "text",
      name: "subject",
      label: "Subject",
      size: 12,
      required: true,
      extraText: "Tell us a few words about your question",
    },

    {
      type: "textarea",
      name: "description",
      label: "Description",
      size: 12,
      required: false,
      extraText:
        "Please enter any additional details regarding your request. If no additional details please enter 'none'",
    },

    {
      type: "file",
      name: "file",
      label: "Attachments(optional)",
      size: 12,
      required: false,
    },
  ],
  "i-need-a-different-size": [
    {
      type: "email",
      name: "email",
      label: "Your email address",
      placeholder: "Enter your email address",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "phone",
      label: "Phone Number",
      placeholder: "Enter your phone number",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Enter your first name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter your last name",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "orderNumber",
      label: "Provide your order number",
      placeholder: "Enter your order number",
      size: 12,
      required: true,
    },
    {
      type: "text",
      name: "itemNumber",
      label:
        "Please provide the item number of the item that needs to be re-sized.",
      size: 12,
      placeholder: "Enter your item number",
      required: true,
      extraText:
        "Please provide the item number of the item that needs to be re-sized.",
    },
    {
      type: "select",
      name: "jewelryType",
      label: "Jewelry Type You Want to Re-Size",
      placeholder: "Enter your Jewelry Type",
      size: 12,
      required: false,
      options: [
        {
          label: "Ring",
          value: "ring",
        },
        {
          label: "Bracelet",
          value: "bracelet",
        },

        {
          label: "Necklace",
          value: "necklace",
        },
      ],
    },
    {
      type: "text",
      name: "subject",
      label: "Subject",
      placeholder: "Enter your subject",
      size: 12,
      required: true,
      extraText: "Tell us a few words about your question",
    },

    {
      type: "textarea",
      name: "description",
      label: "Description",
      placeholder: "Enter your description",
      size: 12,
      required: false,
      extraText:
        "Please enter any additional details regarding your request. If no additional details please enter 'none'",
    },

    {
      type: "file",
      name: "file",
      label: "Attachments(optional)",
      size: 12,
      required: false,
    },
  ],
};

export const colorsVariant = {
  silver: "linear-gradient(227deg, #a0a0a0 0%, #f0f0f0 37%, #bab9b9 100%)",
  white: "linear-gradient(227deg, #a0a0a0 0%, #f0f0f0 37%, #bab9b9 100%)",
  yellow: "linear-gradient(227deg, #d9b434 0%, #fef6d2 37%, #e0c962 100%)",
  rose: "linear-gradient(227deg, #ebcab9 0%, #fdefe8 37%, #e6bea8 100%)",
};

export const socialIcons = [
  {
    id: 1,
    name: "facebook",
    link: "https://www.facebook.com/andorluxuryllp",
    icon: <i className="fa-brands fa-facebook-f"></i>,
  },
  {
    id: 2,
    name: "instagram",
    link: "https://www.instagram.com/andorluxury",
    icon: <i className="fa-brands fa-instagram"></i>,
  },
  {
    id: 1,
    name: "pinterest",
    link: "https://in.pinterest.com/andorluxury",
    icon: <i className="fa-brands fa-pinterest"></i>,
  },
  {
    id: 1,
    name: "youtube",
    link: "https://www.youtube.com/@AndorLuxury",
    icon: <i className="fa-brands fa-youtube"></i>,
  },
];

export const dummyData = [
  {
    white: {
      name: "Stellar White Solitaire Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3824/conversions/image_56-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3825/conversions/image_56-medium.jpg",
      id: 56,
      sku: "AL3-ES.RD-W4",
      tag: "new",
      favourite: true,
      product_price: "46999",
      uk_price: "449.00",
      usa_price: "599.00",
      metatags: "new",
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Stellar Yellow Solitaire Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3830/conversions/1-(7)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3831/conversions/model-(2)-medium.jpg",
      id: 257,
      sku: "AL3-ES.RD-Y4",
      tag: null,
      favourite: false,
      product_price: "46999",
      uk_price: "449.00",
      usa_price: "599.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Stellar Rose Solitaire Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3836/conversions/1-(5)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3837/conversions/model-(1)-medium.jpg",
      id: 258,
      sku: "AL3-ES.RD-R4",
      tag: null,
      favourite: false,
      product_price: "46999",
      uk_price: "449.00",
      usa_price: "599.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Round Classic Elegance Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2658/conversions/4D8A6574-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2659/conversions/4D8A6815-medium.jpg",
      id: 188,
      sku: "AL4-ES.RD-3W4",
      tag: null,
      favourite: false,
      product_price: "64999",
      uk_price: "649.00",
      usa_price: "799.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Round Celestial Grip White Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2648/conversions/1-(4)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2649/conversions/4D8A7328-medium.jpg",
      id: 186,
      sku: "AL4-ES.RD-2W4",
      tag: null,
      favourite: false,
      product_price: "52999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Round Celestial Grip Yellow Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/1892/conversions/1-(2)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/1893/conversions/model-(2)-Y-medium.jpg",
      id: 125,
      sku: "AL4-ES.RD-2Y4",
      tag: null,
      favourite: false,
      product_price: "52999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Round Celestial Grip Rose Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2654/conversions/1-(3)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2655/conversions/model-(3)-R-medium.jpg",
      id: 187,
      sku: "AL4-ES.RD-2R4",
      tag: null,
      favourite: false,
      product_price: "52999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Round Twist Solitaire White Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2029/conversions/1-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2030/conversions/4D8A6624-medium.jpg",
      id: 108,
      sku: "AL4-ES.RD-W4",
      tag: null,
      favourite: false,
      product_price: "51999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Round Twist Solitaire Yellow Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2635/conversions/image_109-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2636/conversions/image_109-medium.jpg",
      id: 109,
      sku: "AL4-ES.RD-Y4",
      tag: null,
      favourite: false,
      product_price: "51999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Round Twist Solitaire Rose Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2011/conversions/3-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2012/conversions/model--(2)-medium.jpg",
      id: 110,
      sku: "AL4-ES.RD-R4",
      tag: null,
      favourite: false,
      product_price: "51999",
      uk_price: "499.00",
      usa_price: "649.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Elliptical Tide White Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4440/conversions/1-(8)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4441/conversions/12-model-W-medium.jpg",
      id: 318,
      sku: "AL4-ESS.OV-W4",
      tag: null,
      favourite: false,
      product_price: "55999",
      uk_price: "549.00",
      usa_price: "699.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Elliptical Tide Yellow Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4428/conversions/1-(7)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4429/conversions/12-model-G-medium.jpg",
      id: 316,
      sku: "AL4-ESS.OV-Y4",
      tag: null,
      favourite: false,
      product_price: "55999",
      uk_price: "549.00",
      usa_price: "699.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Elliptical Tide Rose Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4434/conversions/1-(6)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4435/conversions/12-model-RG-medium.jpg",
      id: 317,
      sku: "AL4-ESS.OV-R4",
      tag: null,
      favourite: false,
      product_price: "55999",
      uk_price: "549.00",
      usa_price: "699.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Opulent Oasis Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3819/conversions/4D8A6727-medium.jpg",
      image_2: null,
      id: 256,
      sku: "AL4-ESS.X-W4",
      tag: null,
      favourite: false,
      product_price: "136999",
      uk_price: "1349.00",
      usa_price: "1699.00",
      metatags: null,
      caption: "14K White Gold, EF, VVS-VS",
    },
  },
  {
    white: {
      name: "Gleaming Orbit Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3816/conversions/Eagle-Prong-4D8A7101-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3817/conversions/Eagle-Prong-4D8A7343-medium.jpg",
      id: 255,
      sku: "AL4-ESS.RD-W4",
      tag: null,
      favourite: false,
      product_price: "149999",
      uk_price: "1449.00",
      usa_price: "1849.00",
      metatags: null,
      caption: "14K White Gold, E, SI 2",
    },
  },
  {
    white: {
      name: "Entangled Hearts Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3812/conversions/4D8A6552-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3813/conversions/4D8A6784-medium.jpg",
      id: 254,
      sku: "AL3-ESS.HT-W4",
      tag: null,
      favourite: false,
      product_price: "200999",
      uk_price: "1949.00",
      usa_price: "2449.00",
      metatags: null,
      caption: "14K White Gold, E, VS 1",
    },
  },
  {
    white: {
      name: "Oval Bliss White Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3782/conversions/image_43-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3783/conversions/image_43-medium.jpg",
      id: 43,
      sku: "AL3-ESS.OV-W4",
      tag: null,
      favourite: false,
      product_price: "108999",
      uk_price: "1049.00",
      usa_price: "1349.00",
      metatags: null,
      caption: "14K White Gold, E, SI 1",
    },
    yellow: {
      name: "Oval Bliss Yellow Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3788/conversions/1-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3789/conversions/06-gold-medium.jpg",
      id: 252,
      sku: "AL3-ESS.OV-Y4",
      tag: null,
      favourite: false,
      product_price: "108999",
      uk_price: "1049.00",
      usa_price: "1349.00",
      metatags: null,
      caption: "14K Yellow Gold, E, SI 1",
    },
    rose: {
      name: "Oval Bliss Rose Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3794/conversions/2-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3795/conversions/06-rose-gold-medium.jpg",
      id: 253,
      sku: "AL3-ESS.OV-R4",
      tag: null,
      favourite: false,
      product_price: "108999",
      uk_price: "1049.00",
      usa_price: "1349.00",
      metatags: null,
      caption: "14K Rose Gold, E, SI 1",
    },
  },
  {
    white: {
      name: "Luxe Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/5004/conversions/image_126-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/5005/conversions/image_126-medium.jpg",
      id: 126,
      sku: "AL3-ESS.CU-W8",
      tag: null,
      favourite: false,
      product_price: "154999",
      uk_price: "1499.00",
      usa_price: "1899.00",
      metatags: null,
      caption: "18K White Gold, E, SI2",
    },
  },
  {
    white: {
      name: "Spiraling Square White Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3808/conversions/image_99-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3809/conversions/image_99-medium.jpg",
      id: 99,
      sku: "AL3-ESS.PR-W4",
      tag: null,
      favourite: false,
      product_price: "89999",
      uk_price: "899.00",
      usa_price: "1099.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Spiraling Square Yellow Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3806/conversions/image_101-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3807/conversions/image_101-medium.jpg",
      id: 101,
      sku: "AL3-ESS.PR-Y4",
      tag: null,
      favourite: false,
      product_price: "89999",
      uk_price: "899.00",
      usa_price: "1099.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Spiraling Square Rose Side Stone Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2780/conversions/image_100-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2781/conversions/image_100-medium.jpg",
      id: 100,
      sku: "AL3-ESS.PR-R4",
      tag: null,
      favourite: false,
      product_price: "89999",
      uk_price: "899.00",
      usa_price: "1099.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Art Deco White Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4368/conversions/3-(4)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4369/conversions/4-model-W-medium.jpg",
      id: 306,
      sku: "AL4-EH.AS-W4",
      tag: null,
      favourite: false,
      product_price: "67999",
      uk_price: "649.00",
      usa_price: "849.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Art Deco Yellow Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4356/conversions/3-(6)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4357/conversions/4-model-G-medium.jpg",
      id: 304,
      sku: "AL4-EH.AS-Y4",
      tag: null,
      favourite: false,
      product_price: "67999",
      uk_price: "649.00",
      usa_price: "849.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Art Deco Rose Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4362/conversions/3-(5)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4363/conversions/4-model-RG-medium.jpg",
      id: 305,
      sku: "AL4-EH.AS-R4",
      tag: null,
      favourite: false,
      product_price: "67999",
      uk_price: "649.00",
      usa_price: "849.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Oval Eclat White Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3693/conversions/1-(2)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3694/conversions/4D8A7149-medium.jpg",
      id: 239,
      sku: "AL4-EH.OV-W4",
      tag: null,
      favourite: false,
      product_price: "124999",
      uk_price: "1199.00",
      usa_price: "1499.00",
      metatags: null,
      caption: "14K White Gold, D, SI 1",
    },
    yellow: {
      name: "Oval Eclat Yellow Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4689/conversions/image_240-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4690/conversions/image_240-medium.jpg",
      id: 240,
      sku: "AL4-EH.OV-Y4",
      tag: null,
      favourite: false,
      product_price: "124999",
      uk_price: "1199.00",
      usa_price: "1499.00",
      metatags: null,
      caption: "14K Yellow Gold, D, SI 1",
    },
    rose: {
      name: "Oval Eclat Rose Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3704/conversions/1-(1)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3705/conversions/4-medium.jpg",
      id: 241,
      sku: "AL4-EH.OV-R4",
      tag: null,
      favourite: false,
      product_price: "124999",
      uk_price: "1199.00",
      usa_price: "1499.00",
      metatags: null,
      caption: "14K Rose Gold, D, SI 1",
    },
  },
  {
    white: {
      name: "Luminous Crown White Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3672/conversions/1-(1)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3673/conversions/4D8A6513-medium.jpg",
      id: 236,
      sku: "AL4-EH.RD-2W4",
      tag: null,
      favourite: false,
      product_price: "119999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K White Gold, D, SI 2",
    },
    yellow: {
      name: "Luminous Crown Yellow Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3678/conversions/1-(2)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3679/conversions/5-medium.jpg",
      id: 237,
      sku: "AL4-EH.RD-2Y4",
      tag: null,
      favourite: false,
      product_price: "119999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K Yellow Gold, D, SI 2",
    },
    rose: {
      name: "Luminous Crown Rose Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3686/conversions/1-(3)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3687/conversions/model--(2)-medium.jpg",
      id: 238,
      sku: "AL4-EH.RD-2R4",
      tag: null,
      favourite: false,
      product_price: "119999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K Rose Gold, D, SI 2",
    },
  },
  {
    white: {
      name: "Round Circlet Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3665/conversions/4D8A7013-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3666/conversions/4D8A7223-medium.jpg",
      id: 235,
      sku: "AL4-EH.RD-W4",
      tag: null,
      favourite: false,
      product_price: "137999",
      uk_price: "1349.00",
      usa_price: "1699.00",
      metatags: null,
      caption: "14K White Gold, E, SI 1",
    },
  },
  {
    white: {
      name: "Arctic Princess Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3660/conversions/1-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3661/conversions/4D8A7032-medium.jpg",
      id: 234,
      sku: "AL4-EH.PRB-W4",
      tag: null,
      favourite: false,
      product_price: "222999",
      uk_price: "2149.00",
      usa_price: "2699.00",
      metatags: null,
      caption: "14K White Gold, Blue, VVS-VS",
    },
  },
  {
    white: {
      name: "Emerald Aquamarine Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3651/conversions/image_117-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3652/conversions/image_117-medium.jpg",
      id: 117,
      sku: "AL3-EH.EMB-W4",
      tag: null,
      favourite: false,
      product_price: "66999",
      uk_price: "649.00",
      usa_price: "799.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Orchid Blossom Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3655/conversions/image_20-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3656/conversions/image_20-medium.jpg",
      id: 20,
      sku: "AL3-EH.RAB-W4",
      tag: null,
      favourite: false,
      product_price: "67999",
      uk_price: "649.00",
      usa_price: "849.00",
      metatags: null,
      caption: "14K White Gold, Blue, VVS-VS",
    },
  },
  {
    white: {
      name: "Olive Emerald Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3653/conversions/image_17-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3654/conversions/image_17-medium.jpg",
      id: 17,
      sku: "AL3-EH.EMG-W4",
      tag: null,
      favourite: false,
      product_price: "68999",
      uk_price: "699.00",
      usa_price: "849.00",
      metatags: null,
      caption: "14K White Gold, Green, VVS-VS",
    },
  },
  {
    white: {
      name: "Oval Silent Symphony White Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4543/conversions/image_242-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4544/conversions/image_242-medium.jpg",
      id: 242,
      sku: "AL4-EHH.OV-W4",
      tag: null,
      favourite: false,
      product_price: "374999",
      uk_price: "3649.00",
      usa_price: "4599.00",
      metatags: null,
      caption: "14K White Gold, FG, VVS-VS",
    },
    yellow: {
      name: "Oval Silent Symphony Yellow Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4555/conversions/image_330-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4556/conversions/image_330-medium.jpg",
      id: 330,
      sku: "AL4-EHH.OV-Y4",
      tag: null,
      favourite: false,
      product_price: "374999",
      uk_price: "3649.00",
      usa_price: "4599.00",
      metatags: null,
      caption: "14K Yellow Gold, FG, VVS-VS",
    },
    rose: {
      name: "Oval Silent Symphony Rose Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4549/conversions/image_331-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/4550/conversions/image_331-medium.jpg",
      id: 331,
      sku: "AL4-EHH.OV-R4",
      tag: null,
      favourite: false,
      product_price: "374999",
      uk_price: "3649.00",
      usa_price: "4599.00",
      metatags: null,
      caption: "14K Rose Gold, FG, VVS-VS",
    },
  },
  {
    white: {
      name: "Secret Square Hidden Halo Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3726/conversions/4D8A6602-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3727/conversions/4D8A6852-medium.jpg",
      id: 243,
      sku: "AL4-EHH.PR-W4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "59999",
      uk_price: "599.00",
      usa_price: "749.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
  },
  {
    white: {
      name: "Emerald Hidden Majesty White Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3718/conversions/image_116-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3719/conversions/image_116-medium.jpg",
      id: 116,
      sku: "AL4-EHH.EM-W4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "135999",
      uk_price: "1299.00",
      usa_price: "1649.00",
      metatags: null,
      caption: "14K White Gold, F, VVS 2",
    },
    yellow: {
      name: "Emerald Hidden Majesty Yellow Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3720/conversions/image_114-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3721/conversions/image_114-medium.jpg",
      id: 114,
      sku: "AL4-EHH.EM-Y4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "135999",
      uk_price: "1299.00",
      usa_price: "1649.00",
      metatags: null,
      caption: "14K Yellow Gold, F, VVS 2",
    },
    rose: {
      name: "Emerald Hidden Majesty Rose Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/1316/conversions/1-(3)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/1317/conversions/model--(2)-medium.jpg",
      id: 115,
      sku: "AL4-EHH.EM-R4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "135999",
      uk_price: "1299.00",
      usa_price: "1649.00",
      metatags: null,
      caption: "14K Rose Gold, F, VVS 2",
    },
  },
  {
    white: {
      name: "Round Secret Halo White Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3722/conversions/image_111-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3723/conversions/image_111-medium.jpg",
      id: 111,
      sku: "AL4-EHH.RD-W4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "118999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K White Gold, E, SI 1",
    },
    yellow: {
      name: "Round Secret Halo Yellow Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2048/conversions/2-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2049/conversions/model--(1)-medium.jpg",
      id: 113,
      sku: "AL4-EHH.RD-Y4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "118999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K Yellow Gold, E, SI 1",
    },
    rose: {
      name: "Round Secret Halo Rose Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2042/conversions/3-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/2043/conversions/model--(2)-medium.jpg",
      id: 112,
      sku: "AL4-EHH.RD-R4",
      tag: "HIDDEN HALO",
      favourite: false,
      product_price: "118999",
      uk_price: "1149.00",
      usa_price: "1449.00",
      metatags: null,
      caption: "14K Rose Gold, E, SI 1",
    },
  },
  {
    white: {
      name: "Regal White Trine Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3882/conversions/image_57-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3883/conversions/image_57-medium.jpg",
      id: 57,
      sku: "AL3-ETS.RD-W4",
      tag: "INCREDIBLE VALUE",
      favourite: false,
      product_price: "75999",
      uk_price: "749.00",
      usa_price: "949.00",
      metatags: null,
      caption: "14K White Gold, DE, VVS-VS",
    },
    yellow: {
      name: "Regal Yellow Trine Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3888/conversions/1-(2)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3889/conversions/model--(2)-medium.jpg",
      id: 261,
      sku: "AL3-ETS.RD-Y4",
      tag: null,
      favourite: false,
      product_price: "75999",
      uk_price: "749.00",
      usa_price: "949.00",
      metatags: null,
      caption: "14K Yellow Gold, DE, VVS-VS",
    },
    rose: {
      name: "Regal Rose Trine Ring",
      image_1:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3894/conversions/1-(1)-medium.jpg",
      image_2:
        "https://andorluxury.blr1.cdn.digitaloceanspaces.com/3895/conversions/model--(3)-medium.jpg",
      id: 262,
      sku: "AL3-ETS.RD-R4",
      tag: null,
      favourite: false,
      product_price: "75999",
      uk_price: "749.00",
      usa_price: "949.00",
      metatags: null,
      caption: "14K Rose Gold, DE, VVS-VS",
    },
  },
];

export const cartShippingFields = [
  // { type: "text", name: "name", label: "Name", size: 12, required: true },
  {
    type: "text",
    name: "firstname",
    label: "First Name",
    size: 6,
    required: true,
  },
  {
    type: "text",
    name: "lastname",
    label: "Last Name",
    size: 6,
    required: true,
  },
  {
    type: "text",
    name: "address1",
    label: "Address 1",
    size: 12,
    required: true,
  },
  {
    type: "text",
    name: "address2",
    label: "Address 2",
    size: 12,
    required: false,
  },
  { type: "text", name: "city", label: "City", size: 6, required: true },
  {
    type: "select",
    name: "state_id",
    label: "State",
    size: 6,
    required: true,
  },
  {
    type: "text",
    name: "postal_code",
    label: "Postal code",
    size: 6,
    required: true,
  },
  {
    type: "select",
    name: "country_id",
    label: "Country",
    size: 6,
    required: true,
  },
];

export const orderStatus = {
  0: "Pending",
  1: "Ordered",
  2: "Processing",
  3: "Ready to ship",
  4: "Shipped",
  5: "Delivered",
  6: "Canceled",
  7: "Returned",
};

export const ringSize = [
  {
    label: 5,
    value: "5",
  },
  {
    label: 6,
    value: "6",
  },

  {
    label: 7,
    value: "7",
  },

  {
    label: 8,
    value: "8",
  },
  {
    label: 9,
    value: "9",
  },
  {
    label: 10,
    value: "10",
  },
];

export const necklesSize = [
  {
    label: 14,
    value: "14",
  },

  {
    label: 16,
    value: "16",
  },
  {
    label: 18,
    value: "18",
  },
];

export const braceletSize = [
  {
    label: 5,
    value: "5",
  },
  {
    label: 5.5,
    value: " 5.5",
  },
  {
    label: 6,
    value: "6",
  },
  {
    label: 6.5,
    value: "6.5",
  },
  {
    label: 7,
    value: "7",
  },
  {
    label: 7.5,
    value: "7.5",
  },
  {
    label: 8,
    value: "8",
  },
  {
    label: 8.5,
    value: "8.5",
  },
];

//====================OUR POLICY==========================================
export const lifeTimeWarranty = [
  {
    title: "WHAT’S COVERED?",
    des: [
      "Andor Luxury is committed to creating the highest quality jewelry that lasts a lifetime. Our goal is to make you happy with your jewelry now and forever. We offer complimentary lifetime cleaning. We also provide complimentary replacement of stones under 0.10ct each, which are broken or lost during everyday wear and tear.",
      "Diamonds above 0.10ct each are not covered under warranty. Center stone diamonds and gemstones on engagement rings are also not covered under warranty. Damage, loss or theft are not covered by our Lifetime Warranty. While we're happy to repair your jewelry, there are fees assessed based on the repair requirements, as determined by our jewelers. We strongly recommend that all work including cleaning, resizing and repairs performed on your jewelry is done by Andor Luxury. Otherwise, your warranty will be voided. Please allow 7-10 business days for normal wear and tear repairs. Timelines may vary are jewelry requiring more extensive repair or recrafting.",
    ],
  },
  {
    title: "HOW DO I USE MY WARRANTY?",
    des: [
      "If you have jewelry that is in need of repair please contact us. If your Andor Luxury jewelry has been repaired, damaged or worked on by another jeweler, the Andor Luxury lifetime warranty policy will expire on that particular jewelry item. Andor Luxury’s free shipping policies do not apply to jewelry repairs. To use your lifetime warranty, eligible repairs/replacements shipping to and from USA simply require a $50 FedEx insured shipping fee (covers both shipping ways). For orders shipping to and from Canada or the UK, there is a $100 FedEx insured shipping fee. The shipping fees apply to all orders being sent for repairs or resizing.",
    ],
  },
  {
    title: "HOW TO CLEAN MY JEWELRY?",
    des: [
      "Andor Luxury wants your jewelry to shine as bright as the day it was purchased. We offer complimentary professional cleaning and polishing (excludes shipping fees). We recommend doing this once a year as part of regular servicing.",
      "For day to day care, we recommend purchasing the Andor Luxury Luxury Jewelry Cleaner. Our cleaner removes dirt, oil, dust and other materials that reduce shine from daily use.",
      "Please see our online disclosure that includes a list of ingredients for the product, designated lists, fragrance allergens, and nonfunctional constituents.",
    ],
  },
];

export const financing = [
  {
    title: "WHAT IS THE ANDOR LUXURY CREDIT CARD?",
    des: [
      "Andor Luxury offers customers a credit card through Synchrony Bank with a revolving credit line to use again and again at andorluxury.com. Please note only customers within the USA are eligible to apply and use the Andor Luxuary Credit Card.",
    ],
  },
  {
    title: "HOW DO I APPLY FOR A ANDOR LUXURY STORE CREDIT CARD?",
    des: [
      "You can apply for a Andor Luxury Store Credit Card here. Get a decision instantly.",
    ],
  },
  {
    title: "WHERE DO I CHECK THE STATUS OF MY ACCOUNT?",
    des: [
      "To check the status of your Andor Luxuary Store Credit Card account,click here to learn more and manage your account on Synchrony Bank's website. After loging into your account, you will be able to: ",
      "Make a payment",
      "View your balance",
      "Update your account information",
      "Schedule up to your next 12 payments",
    ],
  },
  {
    title: "HOW CAN I MAKE A PAYMENT ON MY ANDOR LUXURY STORE CREDIT CARD?",
    des: [
      "Pay Online",
      "Click here to manage your account and make payments.",
      "Pay by Phone",
      "Contact Synchrony Bank Customer Service at 1-866-396-8254.",
      "Office Hours:",
      "Mon thru Fri - 7:30am to 12:00 midnight EST Sat - 10:00am to 7:00pm EST Sun – Closed",
      "Pay by Mail",
      "Mail payments to the address below. Please ensure that you have plenty of time for your payment to arrive prior to your due date.",
      "Synchrony BankPO Box 960061Orlando, FL 32896-0061",
    ],
  },
  {
    title: "WHAT IS AFFIRM FINANCING?",
    des: [
      "Use Affirm to make up to 4 interest free payments. Or pay with installments on your own schedule over a 36 month period. Add to cart and choose how you want to buy now and pay later with Affirm.",
      "Your rate will be 0-36% APR based on credit, and is subject to an eligibility check. For example, a $700 purchase might cost $63.18/mo over 12 months at 15% APR. Payment options through Affirm are provided by these lending partners: affirm.com/lenders. Options depend on your purchase amount and a down payment may be required.",
    ],
  },
];

export const replicahomepreview = [
  {
    title: "HOW DOES IT WORK?",
    des: [
      "Andor Luxury's Replica Ring Preview makes it easy for you to select the perfect ring. You can experience your favorite styles in the comfort of your home before you purchase. Choose up to 2 replica rings to preview for 3 days. Share with friends and family or discreetly find a ring style she will love. After you are done viewing, simply use the prepaid shipping label and return via USPS in the original packaging. All replica orders ship USPS free of charge to and from the customer. Please note that Home Preview orders can only ship within the USA. Home Preview orders cannot be shipped to addresses in Canada or the UK.",
    ],
  },
  {
    title: "IS THE HOME PREVIEW FREE?",
    des: [
      "The Home Preview service is 100% free. We simply want you to easily and conveniently find the perfect ring style. When you place an order, Andor Luxury will place a $50 charge per home preview ring on your credit card as verification of customer billing information. We value your privacy, your information is held in confidence. The authorization will be fully refunded after the replica rings are returned. If the replica rings are not returned for any reason, or are returned damaged beyond repair, your credit card will be charged $50 per ring. By submitting your home preview order, you authorize Andor Luxury to charge your credit card.",
    ],
  },
  {
    title: "WHAT ARE REPLICA RINGS?",
    des: [
      "All our replica rings look exactly like real Andor Luxury engagement rings. Our Replica Rings are made of a white metallic alloy and round one carat diamond simulant. Just by looking at it, it’s hard to tell a Replica Ring apart from a real diamond ring. Replica Rings are only intended for visual purposes, not extended wear, which may result in damage or tarnish.",
    ],
  },
  {
    title: "HOW DO I EXTEND MY PREVIEW?",
    des: [
      "We are happy to speak to you about extending your preview period. We understand that it can take some additional time to make a lifetime commitment. :) Simply contact us and let us know. If your home preview ends on a weekend or holiday, just return the package on the next available day.",
    ],
  },
  {
    title: "HOW MANY HOME PREVIEW ORDERS CAN I PLACE?",
    des: [
      "We are able to send up to 2 home preview orders per customer at a time, with a limit of two orders per customer. Each Home Preview order includes up to 2 rings. We’re always available to help you find the perfect ring style or tell you more about our rings to help you make a better choice. At a complete loss? Call in and just describe her personality and style and our design team can find the perfect ring for you, we promise!",
    ],
  },
];

export const diamondethics = [
  {
    title: "WHAT ARE CONFLICT DIAMONDS?",
    des: [
      "Andor Luxury's Replica Ring Preview makes it easy for you to select the perfect ring. You can experience your favorite styles in the comfort of your home before you purchase. Choose up to 2 replica rings to preview for 3 days. Share with friends and family or discreetly find a ring style she will love. After you are done viewing, simply use the prepaid shipping label and return via USPS in the original packaging. All replica orders ship USPS free of charge to and from the customer. Please note that Home Preview orders can only ship within the USA. Home Preview orders cannot be shipped to addresses in Canada or the UK.",
    ],
  },
  {
    title: "WHAT IS THE KIMBERLEY PROCESS?",
    des: [
      "The Home Preview service is 100% free. We simply want you to easily and conveniently find the perfect ring style. When you place an order, Andor Luxury will place a $50 charge per home preview ring on your credit card as verification of customer billing information. We value your privacy, your information is held in confidence. The authorization will be fully refunded after the replica rings are returned. If the replica rings are not returned for any reason, or are returned damaged beyond repair, your credit card will be charged $50 per ring. By submitting your home preview order, you authorize Andor Luxury to charge your credit card.",
    ],
  },
  {
    title: "WHAT ARE ZIMBABWE DIAMONDS?",
    des: [
      "All our replica rings look exactly like real Andor Luxury engagement rings. Our Replica Rings are made of a white metallic alloy and round one carat diamond simulant. Just by looking at it, it’s hard to tell a Replica Ring apart from a real diamond ring. Replica Rings are only intended for visual purposes, not extended wear, which may result in damage or tarnish.",
    ],
  },
  {
    title: "WHAT IS ANDOR LUXURY’S CONFLICT DIAMOND POLICY?",
    des: [
      "We are happy to speak to you about extending your preview period. We understand that it can take some additional time to make a lifetime commitment. :) Simply contact us and let us know. If your home preview ends on a weekend or holiday, just return the package on the next available day.",
    ],
  },
];

export const insuredshipping = [
  {
    title: "HOW ARE ORDERS SHIPPED?",
    des: [
      "All Andor Luxury customers enjoy complimentary insured shipping on orders shipped within the United States. We provide shipment tracking so that you can keep track of when your package will arrive. All items shipping via FedEx will require a signature upon delivery.",
      "We offer the following shipping options:",
      "FedEx Insured 2-Day Shipping",
      "FedEx Insured Overnight (Additional $40)",
      "USPS Priority Mail® - APO / FPO Shipments",
      "Andor Luxury insured shipping covers items that are lost, damaged or stolen during transit. We will make efforts to recover the item(s). If unrecoverable, we will offer an item of equal or similar quality and size to match your original order. Insurance coverage applies to the cost (not retail value) of the item, as provided by our insurers.",
    ],
  },
  {
    title: "HOW CAN I SHIP TO AN ALTERNATIVE SHIPPING ADDRESS?",
    des: [
      "If your billing and shipping address are different, Andor Luxury will gladly ship your jewelry to the preferred location. For security verification, we may use an email confirmation, government issued ID verification and/or telephone call to confirm the desired delivery address.",
    ],
  },
  {
    title: "HOW LONG DOES IT TAKE FOR AN ORDER TO REACH ME?",
    des: [
      "Andor Luxury provides estimated shipping information to help you understand when you can expect to receive your order. We begin processing orders the day they are received and do our absolute best to minimize delays. Please understand that each order requires special attention and our Andor Luxury craftsmen are dedicated to placing quality above all else. Loose diamond orders typically ship in 5-7 business days and finished rings and other jewelry in up to 12 business days. Please consider an additional day for third party lab appraisal services. If you require urgent processing or rushed shipping, please call us at (844) 234-6463 to discuss the available options.",
    ],
  },
  {
    title: "HOW IS SALES TAX DETERMINED?",
    des: [
      "Sales tax is assessed based on a customer’s shipping address. Orders in the State of New York,California,Texas, Arizona, Illinois, Delaware, Arkansas, Georgia, Indiana, Iowa, Kansas, Kentucky, Michigan, Minnesota, Nebraska, Nevada, New Jersey, North Carolina, North Dakota, Ohio, Oklahoma, Rhode Island, South Dakota, Tennessee, Utah, Vermont, Washington, West Virginia, Wisconsin, and Wyoming are subject to sales tax.",
      "For international orders shipping to the UK or Canada, VAT is assessed based on the shipping address. Once the item is shipped, the FedEx broker will contact you about the cost and payment for the duties.",
    ],
  },
  {
    title: "WHERE CAN ORDERS BE SHIPPED?",
    des: [
      "Andor Luxury ships to personal residences, offices, and local FedEx/UPS locations. We do not ship to PO Boxes for security reasons. We can ship anywhere within the USA, Canada or the UK.",
    ],
  },
];

export const returns = [
  {
    title: "HOW DO I RETURN MY PURCHASE?",
    des: [
      "Our primary goal is that you love your jewelry. Should it not meet your expectations, we offer a hassle-free return policy. If you wish to return your jewelry or diamond for any reason, your order would be eligible for return or exchange within 30 calendar days from the date of your receipt.",
      "Our primary goal is that you love your jewelry. Should it not meet your expectations, we offer a hassle-free return policy. If you wish to return your jewelry or diamond for any reason, your order would be eligible for return or exchange within 30 calendar days from the date of your receipt.",
      "Please note that the following items are not eligible for return:",
      "Fine jewelry items that have been personalized (such as Personalized Name Necklaces) are non-returnable.",
      "Diamond upgrades are not eligible for return or exchange.",
      "Rings and/or loose diamond orders with damaged or lost center diamonds are not eligible for return.",
      "Jewelry or rings that have chips, scratches, breaks or nicks will not be eligible for return.",
      "All Andor Luxury jewelry is marked with microscopic trademark etchings. If jewelry is worked on, resized or repaired by another jeweler it will not be eligible for returns.",
      "Loose diamond purchases with a D FL color / clarity quality with a GIA seal are non-returnable if the GIA seal is broken.",
      "Purchase Policy: Customers are also limited to 1 purchase at a time within 30 days of receiving the order.",
    ],
  },
  {
    title: "ARE RETURNS FREE?",
    des: [
      "Returns for orders from the US are free and include the following:",
      "Free Shipping: Simply print your return label, repackage all purchased items in their original packaging, include any diamond certifications, and schedule a FedEx / UPS pick-up or drop it off at your nearest FedEx / UPS location.",
      "Free Return Insurance: Our pre-paid postage labels include insurance on the full value of your purchase.",
      "Please Note: Failure to return a diamond certificate will result in a $250 fee. Appraisal reports are issued by a third party and are non-refundable.Returns for orders from the UK, Canada and Australia will be charged as $60, $60 & $75 respectively. Duties and taxes are recovered by the customer after a return/exchange balance from their local and/or federal tax authorities for international orders.",
    ],
  },
  {
    title: "WHAT IS THE RETURN PROCESS?",
    des: [
      "Please allow Andor Luxury up to 7 business days to process returns and inspect returned jewelry, from the date on which your return is received. Once Andor Luxury receives your returned jewelry, you will receive a confirmation that your return is being processed. Returns are at the sole discretion of Andor Luxury based on the condition of the jewelry. If your purchase included a GIA or IGI certificate, please be sure to return that. If a certificate is not returned or returned with damage, there will be a charge of $300 in order to re-certify the diamond. Once inspected and approved, your return will be completed and you will receive a confirmation email with details about your refund. Refunds will be made via the original method of payment. Please allow time for the refund to be processed. A refund will generally take less than 7 business days to be completed. All refunds will be made in USD. There may be minor discrepancies based on exchange rates.",
    ],
  },
  {
    title: "HOW DO I CANCEL MY ORDER?",
    des: [
      "Unsure of your purchase? Not a problem! Orders can be cancelled within the first 48 hours. Simply email support@andorluxury.com with a reason for the cancellation. After 48 hours, the order will need to go through the returns process. Refunds are processed only to the original payment method and take up to 5-7 business days. One order cancellation is permitted within a 6 month period. A second order within the same six month period is final sale.",
    ],
  },
];

export const customrings = [
  {
    title: "HOW DO I ORDER CUSTOM DESIGNED JEWELRY?",
    des: [
      "We’re happy to create the perfect jewelry for you, based on a custom design. Simply send an image and description of your desired jewelry to support@andorluxury.com We will then confirm that we can create the design for you and provide a custom quote. If any changes need to be made, we will work closely with you during the custom process to ensure that your jewelry is perfect. Our expert designers will provide you with a complimentary rendering and a quote for your approval before your jewelry is crafted with one complimentary revision. Once a design is approved through the computer animated design process and paid for, the order may not be cancelled or changed. Quotations on custom jewelry are valid for 30 days and subject to change thereafter. If you'd like to design and purchase a custom setting, you would be required to purchase the center diamond/gemstone from Andor Luxury.",
      "Custom settings alone are not available for purchase. Learn more about our custom design process. Custom jewelry is eligible for returns within 30 days of receipt. Our lifetime warranty will apply to all custom jewelry. However, if the jewelry is worked on by another jeweler, the warranty will be void as we cannot guarantee the work of another jeweler.",
    ],
  },
];

export const appraisalandupgrades = [
  {
    title: "WHAT IS A JEWELRY APPRAISAL?",
    des: [
      "A jewelry appraisal ascertains the retail value of your jewelry. Gemstone jewelry will take into account the millimeter dimensions of the stones and the quality of the item. With your appraisal, you can have your jewelry insured and make a claim in case of loss, theft or damage. For a fee of $60 we can have your jewelry appraised by a third party lab and send you the original appraisal documents with your order. To request an appraisal, simply email or call us once you have placed your order. Please note that appraisals are not refundable, even if you are returning your jewelry or diamond.",
    ],
  },
  {
    title: "WHAT IS YOUR DIAMOND UPGRADE POLICY?",
    des: [
      "We offer a lifetime upgrade policy for all Natural GIA certified diamonds purchased from Andor Luxury, with proof of receipt. If you would like to upgrade your diamond, we’ll apply 100% of the credit toward the purchase of a new GIA certified diamond priced at least twice the original diamond purchase. Diamonds for upgrade are not eligible for return/exchange. GIA certified diamonds can only be upgraded for another GIA certified diamond. Please note that the diamond upgrade policy only applies to loose diamonds or diamonds that are purchased as a part of the Build Your Ring catalog. The certified diamond must be in its original condition and accompanied by the original grading report. The diamond will need to be inspected and approved by one of our certified gemologists to ensure authenticity. Diamond upgrades do not apply to ring or jewelry settings. If the diamond's ring needs to be altered to accommodate the new diamond selection, additional fees may apply. Not all ring settings can accommodate a diamond upgrade. Diamonds that are eligible for upgrades are not available for price match. Lab created IGI certified and non-certified lab created diamonds are not eligible for the upgrade policy. Lab created diamonds and/or rings are considered final sale after the return/exchange period.",
      "If you’d like to upgrade, simply contact our sales department at (844) 234-6463 and send your jewelry in for inspection. Choose your upgrade and we’ll apply the value of your original diamond purchase as credit against the new purchase. We can then send you the diamond loose or reset it in a new ring setting.",
    ],
  },
];

export const diamondpricematch = [
  {
    title: "HOW DOES A DIAMOND QUALIFY FOR THE PRICE MATCH GUARANTEE?",
    des: [
      "Andor Luxury has the best diamond prices. However, if you do find the same lab grown diamond (IGI or GIA certificate number must match), for a lower price on another retailers website, we will match that price. Note, that the diamond must be the same and the price must be published to the live link on the website. Andor Luxury reserves the right to verify and check on the diamond availability, price and link. The lab grown diamond must be available to purchase on the comparative retail site. Please note that the diamond price match policy is only applicable to lab grown diamonds. We do not offer a price match for certified natural diamonds or finished diamond jewelry.",
    ],
  },
  {
    title: "WHAT ELSE SHOULD I KNOW ABOUT THE PRICE MATCH GUARANTEE?",
    des: [
      "All price match requests must be submitted prior to purchase/sale. A diamond price match cannot be combined with any other promotional offers, gifts or discounts. The competing price should be available to the public for retail sale, and not a wholesale price. The price match guarantee applies to the lab grown diamond before any unique discounts or expired discounts. Competing lab grown diamond prices must be in the same currency. Diamonds that are eligible for upgrades are not available for price match. Andor Luxury also reserves the right to ensure that the published price is not an error. We will honor the matched price for 24 hours from the date that it is shared with the customer.",
    ],
  },
];

export const crafting = [
  {
    title: "IS ENGRAVING FREE?",
    des: [
      "We’re excited to be a part of your big day! We’re happy to offer complimentary engraving on engagement rings or wedding bands (subject to space availability in the jewelry). Please note that if your ring is later resized or polished, there is a $25 re-engraving fee.",
    ],
  },
  {
    title: "WHAT CAN I GET ENGRAVED?",
    des: [
      "During your checkout process, include your desired engraving. Characters can include numbers or letters. All rings and bands can be engraved with up to 15 characters. Common engravings include names, marriage and engagement dates, religious verses, song lyrics, or anything that has a special meaning for you and your significant other. Inside jokes, puns and nicknames (the mushier the better) are also great options.",
    ],
  },
  {
    title: "CAN ALL RINGS AND BANDS BE ENGRAVED?",
    des: [
      "Certain eternity rings and bands cannot be engraved. Please contact us if you have specific questions about your chosen style. We will contact you to explain if a ring or band you ordered cannot be engraved for any reason.",
    ],
  },
  {
    title: "HOW DO I FIND THE RIGHT SIZE?",
    des: [
      "When measuring your finger, a ring should fit snug and comfortably. The most accurate way to find a good fit is by using a ring sizer or having a jeweler measure your finger. Alternatively, have a jeweler measure a ring that is worn on the ring finger to determine the ring size. Fingers may slightly swell in warm weather, so be sure to choose a size that fits comfortably over the knuckle and does not fall off when moving your hand. We’re happy to create rings in quarter sizes and in-between sizes to ensure we can craft the perfect fit.",
    ],
  },
  {
    title: "WHAT CAN I GET RESIZED?",
    des: [
      "Andor Luxury recognizes that ring sizing is not a perfect science. To this end, we offer one free resizing within 30 days of your purchase for all orders shipped within the USA, UK, Canada & Australia. For orders shipping to and from USA addresses, there is a $50 roundtrip FedEx insured shipping fee that applies. For orders shipping to and from UK, Canada & Australia addresses, there is a $100 roundtrip insured FedEx shipping fee that applies. Any resizing up will not be subject to additional metal charges. During the resize process small amounts of metal will be added or removed to increase or decrease the size to adjust for the new size needed. As a result, your ring may be slightly thicker or thinner when completed. Please be aware that a resizing down may result in a slight reduction in metal weight, which cannot be reimbursed.",
      "We request up to 1 week to perform a resizing, excluding shipping days. Please Note: Eternity rings cannot be resized or exchanged outside our 30-day return policy.",
      "Any resizing request outside 30 days will be subject to fees, including shipping and resizing fees. In most cases, subject to Andor Luxury's discretion, a ring resizing under 1 US finger size will be accompanied by shipping charges plus $30 for 14kt gold, $40 for 18kt gold and $50 for platinum. For resizing above or below 1 finger size, a new ring will need to be re-crafted to preserve the quality and structural integrity of the jewelry. In such instances, please contact our team for a quotation.",
      "Rings that are engraved or repaired will require a $25 re-engraving fee.",
    ],
  },
  {
    title: "HOW ARE ACCENT DIAMONDS CHOSEN FOR MY ENGAGEMENT RING?",
    des: [
      "The type of accent diamonds, if present in your engagement ring setting design will match the type of your selected center diamond. Lab grown accent diamonds to match a lab grown center diamond and natural accent diamonds to match a natural center diamond. Accent diamonds are hand-picked to ensure they match each other and the center diamond.",
    ],
  },
];

export const detailFaq = {
  1: [
    {
      question: "How much should I spend on an engagement ring?",
      answer:
        "No matter what, diamonds are a huge investment. However, there is no 'one size fits all' answer. Andor Luxury has a wide range of engagement rings to suit all budgets.",
    },
    {
      question: "What size diamond is best for an engagement ring?",
      answer:
        "The most popular diamond size chosen for engagement rings is a 1.0 carat center stone. However, couples can opt for both smaller and larger diamonds. Another great and more affordable option at Andor Luxury is lab grown diamonds. Lab grown diamonds are the next generation of diamonds that give you the benefit of purchasing a larger size diamond at a more affordable price.",
    },
    {
      question: "What are the different diamond shapes for engagement rings?",
      answer:
        "There is a selection of diamond shapes to choose from. These include round, princess, cushion, emerald, oval, radiant, Asscher, marquise, pear and heart shaped diamonds.",
    },
    {
      question: "How to choose diamond engagement rings?",
      answer:
        "Buying an engagement ring comes with many questions. At Andor Luxury, we're here to offer you 100% free advice and will assist you in understanding the best diamond quality for your budget and unique preferences. Our non-commissioned diamond and jewelry experts are available 24/7. Contact us here.",
    },
    {
      question: "What are the 4Cs?",
      answer:
        "The 4Cs include carat (size), color, clarity and cut, and are the globally accepted standards by the Gemological Institute of America (GIA) used to assess the quality of the diamond.",
    },
    {
      question: "How to measure ring size?",
      answer:
        "There are several methods available to determine your ring size (or the ring size of a loved one). Please click here for a full list of the options available to you.",
    },
    {
      question: "What hand do engagement rings go on?",
      answer:
        "Traditionally, an engagement ring is worn on the left hand. However, in many countries and cultures around the world this may differ.",
    },
  ],
  2: [
    {
      question: "When should the wedding rings be purchased?",
      answer:
        "It is recommended that wedding rings be purchased 3 to 4 months prior to the wedding.",
    },
    {
      question: "Who buys the wedding rings?",
      answer:
        "Traditionally, each partner buys the wedding ring for the other person. Many couples also prefer to choose and buy wedding rings together.",
    },
    {
      question: "What metal should a wedding ring be?",
      answer:
        "The wedding ring should be any metal you choose! Gold is the most common metal and provides the most options for any style or budget due to its availability in yellow, white, or rose. Platinum, a luxurious, rare, and durable metal, is also becoming increasingly popular.",
    },
    {
      question: "Where do you wear a wedding ring?",
      answer:
        "Wedding rings are most commonly worn on the same finger as an engagement ring, which is frequently the ring finger on the left hand.However, this practice is different for cultures and countries.",
    },
    {
      question: "How much do wedding rings cost?",
      answer:
        " There is no precise amount a wedding ring should cost. Prices will vary depending on the amount and sizes of diamonds and gemstones, as well as the metal choice. Luckily, Andor Luxury carries wedding rings to fit every budget.",
    },
    {
      question: "Do wedding rings need to match?",
      answer:
        "In a word: no! Wedding rings can match each other, can complement the engagement ring, and/or can reflect the wearer’s individual style.",
    },
    {
      question:
        "What is the difference between a wedding ring and an eternity ring?",
      answer:
        "Wedding rings traditionally feature diamonds halfway around the band or are plain metal only, while eternity rings feature diamonds that go all the way around the band.",
    },
    {
      question: "When do you buy an eternity ring?",
      answer:
        "An eternity ring is typically given on an anniversary or a special occasion, including the birth of a child. Many choose to use an eternity ring as a wedding ring.",
    },
    {
      question: "How much does an eternity ring cost?",
      answer:
        "Just like wedding rings, there is no precise amount an eternity ring should cost, as the price is affected by the number of diamonds and gemstones, as well as the metal choice. Also like wedding rings, Andor Luxury carries a variety of eternity rings to fit your budget.",
    },
    {
      question: "How do you figure out the ring size?",
      answer:
        "It is easier than you think to figure out the ring size. Andor Luxury provides multiple options including a virtual ring sizer. Click here for more information.",
    },
    {
      question: "What do the 4Cs mean?",
      answer:
        "The 4Cs stand for carat, cut, color, and clarity. The 4Cs are the worldwide standard accepted by the Gemological Institute of America (GIA),to determine a diamond’s quality.",
    },
  ],
  3: [
    {
      question: "What are GIA, AGS, and IGI diamond certificates?",
      answer:
        "A Gemological Institute of America (GIA), American Gem Society (AGS), or International Gemological Institute (IGI) diamond certificate assures the value and quality of your diamond. GIA, AGS, and IGI are the gold standard in diamond grading. Their reports detail key characteristics of each diamond.",
    },

    {
      question: " What does 'Cut' mean?",
      answer:
        "A diamond's cut determines how effectively it returns light to the viewer's eye. A well-cut diamond appears brilliant and fiery, while poorly cut stones look dark and lifeless. Well-cut diamonds often appear larger than stones of the same carat weight. ",
    },
    {
      question: "What does the term 'eye-clean' mean?",
      answer:
        "'Eye-Clean' is a term used to describe a diamond that is clean to an unaided eye in a face-up position from a distance of 8 to 10 inches. Diamonds of VS2 or higher in quality will usually be eye-clean. Diamonds of SI1, SI2, and I1 need to be visually inspected to determine whether any inclusions are visible from the top. We can inspect any diamond at no charge.",
    },
    {
      question: "What is the quality of the diamonds used in your jewelry?",
      answer:
        "Smaller diamonds (often called melee) are cut in factories around the world and are available in a variety of qualities.",
    },
    {
      question: "Do the 18K white gold rings turn yellow over time?",
      answer:
        "Yes, very slightly. In order to give 18K White Gold a pure white color, it is plated with rhodium. This is a standard practice for 14K and 18K White Gold jewelry. Over time, the rhodium plating may wear off.",
    },
    {
      question: "How often do you add diamonds to your inventory?",
      answer: "We add new diamonds to our inventory on a daily basis.",
    },
  ],
};

export const inspiredDummyData = [
  // {
  //   title: "Engagement Rings",
  //   link: "/panoramic-sky-emerald-halo-pendant/AL3-JP2.EMB-2W4",
  //   image: imageURL2.image_181,
  // },
  {
    title: "Women's Wedding Rings",
    link: "/twin-pear-toi-et-moi-ring/AL4-WTM.PSS-W4",
    image:  imageURL2.image_182,
  },
  {
    title: "Gemstone Rings",
    link: "/diamonds-droplet-necklace/AL4-JP4.RD-W4",
    image: imageURL2.image_183,
  },
  {
    title: "Men's Wedding Rings",
    link: "/eternity-bands",
    image: imageURL2.image_184,
  },
  {
    title: "Fine Jewelry",
    link: "/willow's-heart-studs/AL3-JER1.HT-W4",
    image: imageURL2.image_185,
  },
  {
    title: "Fine Jewelry",
    link: "/luxe-luminary-round-bracelet/AL4-JBR1.RD-W4",
    image: imageURL2.image_186,
  },
  {
    title: "Fine Jewelry",
    link: "/sunny-marquise-garden-pendant/AL3-JP2.MQ-2W4",
    image: imageURL2.image_187,
  },
  {
    title: "Fine Jewelry",
    link: "/duet-of-hearts-toi-et-moi-pendant/AL4-JP2.X-W4",
    image: imageURL2.image_188,
  },
  {
    title: "Fine Jewelry",
    link: "/sparkling-serenity-eternity-band/AL3-WEB.MQ-W4",
    image: imageURL2.image_189,
  },
  {
    title: "Fine Jewelry",
    link: "/azure-twilight-toi-et-moi-ring/AL4-WTM.XB-W4",
    image: imageURL2.image_190,
  },
];
